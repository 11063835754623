<template>
  <div class="input-wrapper">
    <span class="input-label">{{ label }}:</span>
    <el-input
      :type="type"
      :value="internalValue"
      :placeholder="placeholder"
      @input="updateModel"
      :disabled="disabled"
      :max="max"
    />
  </div>
</template>
<script setup>
import { defineProps, ref, defineEmits, watch } from 'vue'

const props = defineProps({
  label: {
    type: String,
    default: ''
  },
  type: {
    type: String,
    default: 'text'
  },
  placeholder: {
    type: String,
    default: ''
  },
  modelValue: {
    type: [String, Number],
    default: ''
  },
  disabled: {
    type: Boolean,
    default: false
  },
  max: {
    type: Number,
    default: Infinity
  }
})
watch(
  () => props.modelValue,
  newValue => {
    internalValue.value = newValue
  }
)
const emits = defineEmits(['update:modelValue'])
const internalValue = ref(props.modelValue)
const updateModel = value => {
  if (props.type === 'number') {
    value = Number(value)
    if (!isNaN(value) && value >= props.max) {
      value = props.max
    }
  }

  internalValue.value = value
  emits('update:modelValue', value)
}
</script>

<style lang="scss" scoped>
.input-wrapper {
  display: flex;
  flex-direction: column;
  background-color: #edeff3;
  border-radius: 12px;
  padding: 6px 12px;
  width: 100%;
  height: 50px;
  .input-label {
    font-size: 13px;
    line-height: 0px;
    font-weight: 700;
    color: #292929;
    display: block;
    margin-top: 8px;
  }
  &:deep(.el-input__inner) {
    background: none;
    border: none;
    margin-left: 2px;
    padding: 0;
    color: #292929;
  }
}
</style>
