<template>
  <div class="custom-dialog" ref="customDialog" tabindex="0" @keyup.esc="$emit('close')">
    <div class="dialog-content">
      <button @click="close" class="close-button">
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12 1.20857L10.7914 0L6 4.79143L1.20857 0L0 1.20857L4.79143 6L0 10.7914L1.20857 12L6 7.20857L10.7914 12L12 10.7914L7.20857 6L12 1.20857Z"
            fill="#828D9B"
          />
        </svg>
      </button>
      <div class="title">
        <InfoIcon />

        <span class="mx-2">{{ $t('events.imageview.use.keyboard') }}</span>
        <Arrows />
        <span class="ml-2">{{ $t('events.imageview.use.keyboard1') }}</span>
      </div>
      <img :src="currentImageUrl" alt="preview" />
    </div>
    <div class="overlay" @click="close"></div>
  </div>
</template>

<script>
import InfoIcon from '../assets/icons/info.vue'
import Arrows from '../assets/icons/arrows.vue'
export default {
  components: {
    InfoIcon,
    Arrows
  },
  props: {
    visible: Boolean,
    images: Array,
    currentIndex: Number
  },
  computed: {
    currentImageUrl() {
      return this.images[this.currentIndex].originalUrl
    }
  },

  methods: {
    close() {
      this.$emit('close')
    },
    showNext() {
      //   event.preventDefault()
      // Emit an event to show the next image
      this.$emit('next')
    },
    showPrevious() {
      //   event.preventDefault()
      // Emit an event to show the previous image
      this.$emit('previous')
    },
    handleKeyDown(event) {
      if (event.key === 'ArrowRight') {
        this.showNext()
      } else if (event.key === 'ArrowLeft') {
        this.showPrevious()
      }
    }
  },
  mounted() {
    // add event listener for keyboard events to show the next or previous image
    document.addEventListener('keydown', this.handleKeyDown)
    this.$refs.customDialog.focus()
  }
}
</script>

<style lang="scss" scoped>
.custom-dialog {
  position: fixed;
  top: 56px;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  z-index: 99;

  &:focus {
    outline: none;
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.308);
}

.dialog-content {
  position: relative;
  width: auto;
  min-width: 800px;
  max-width: 98vw;
  height: auto;
  min-height: 600px;
  max-height: calc(98vh - 112px);
  z-index: 10;
  background: #595959;
  border-radius: 12px;
  display: flex;

  align-items: center;
  justify-content: center;
  overflow: hidden;
  img {
    border-radius: 10px;
    max-height: 100%;
    max-width: 100%;
    object-fit: contain;
  }
}
.title {
  padding: 0 10px;
  background-color: #fff;
  border-radius: 20px;
  position: absolute;
  top: 10px;
  left: 0;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  font-size: 14px;
  font-family: Source Sans Pro;
  font-weight: 600;
  line-height: 17px;
  color: #292929;
}
.close-button {
  position: absolute;
  top: 10px;
  right: 0;
  z-index: 10;
  background-color: #f6f7fa;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.next-button {
  right: 10px;
}

.previous-button {
  left: 10px;
}
</style>
