<template>
  <div class="select-wrapper">
    <label class="label">{{ label }}:</label>
    <el-select
      v-model="selectedValue"
      class="custom-select"
      :filterable="filterable"
      :placeholder="placeholder"
      :disabled="disabled"
      @change="handleChanges"
      :allowCreate="allowCreate"
    >
      <template v-if="optionsAreObjects">
        <el-option
          v-for="(item, index) in options"
          :key="index"
          :label="item.label ? item.label : item.name"
          :value="item.value ? item.value : item.id"
        />
      </template>
      <template v-else>
        <el-option
          v-for="(option, index) in options"
          :key="index"
          :label="option"
          :value="option"
        />
      </template>
    </el-select>
  </div>
</template>

<script setup>
import { defineEmits, defineProps, ref, watch, onMounted } from 'vue'

const props = defineProps({
  allowCreate: {
    type: Boolean,
    default: false
  },
  options: {
    type: Array,
    default: () => []
  },
  label: {
    type: String,
    default: ''
  },
  placeholder: {
    type: String,
    default: ''
  },
  modelValue: {
    type: [String, Object],
    default: () => ({})
  },
  disabled: {
    type: Boolean,
    default: false
  },
  filterable: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(['update:modelValue'])
const optionsAreObjects = ref(typeof props.options[0] === 'object')
const selectedValue = ref(props.modelValue)

// Watch for changes in the modelValue prop and update selectedValue
watch(
  () => props.modelValue,
  newValue => {
    if (props.options.value) {
      const selectedOption = props.options.find(option => option.value === newValue)
      if (selectedOption) {
        selectedValue.value = selectedOption.value
      }
    } else {
      selectedValue.value = newValue
    }
  }
)
const handleChanges = value => {
  if (optionsAreObjects.value) {
    const selectedOption = props.options.find(option => {
      return option.value === value
    })
    if (selectedOption) {
      emit('update:modelValue', selectedOption)
    } else {
      let selectedOption = props.options.find(option => option.id === value)
      emit('update:modelValue', selectedOption.id)
    }
  } else {
    emit('update:modelValue', value)
  }
}
onMounted(() => {
  // set default el-select value to the first option if we create a new event
  if (optionsAreObjects.value) {
    const firstOption = props.options[0]
    selectedValue.value = firstOption.value
  }
})
</script>

<style lang="scss">
.ui-select {
  .el-select-dropdown__item {
    width: 394px;
  }
}
</style>
<style lang="scss" scoped>
.select-wrapper {
  display: flex;
  flex-direction: column;
  background-color: #edeff3;
  border-radius: 12px;
  padding: 6px 12px;
  width: 100%;
  height: 50px;
  .label {
    font-size: 13px;
    line-height: 0px;
    font-weight: 700;
    color: #292929;
    display: block;
    margin-top: 8px;
  }
  &:deep(.custom-select) {
    width: 100%;
    .el-input__inner {
      background-color: #edeff3;
      border: none;
      padding-left: 0;
      height: 20px;
      color: #292929;
      font-weight: normal;
    }
    .el-input__suffix {
      height: 20px;
    }
    .el-select__caret {
      font-size: 16px;
      color: #292929;
    }
  }
}
</style>
