<template>
  <div
    class="overflow-y-auto absolute bottom-5 top-5 left-5 z-30 bg-white shadow w-module rounded-20"
  >
    <div class="header flex justify-between items-center pr-4 pl-6 pt-5 pb-2">
      <div class="text-xl font-bold whitespace-no-wrap font-SourceSansPro flex flex-col">
        {{
          title === 1
            ? $t('events.create')
            : title === 2
            ? $t('events.update')
            : $t('events.show')
        }}
        <span class="text-sm font-normal text-gray-500"> {{ subtitle }}</span>
      </div>
      <CloseIcon @click.native="$emit('close')" class="cursor-pointer" />
    </div>
    <div class="pr-4 pl-6 mt-6">
      <el-form :model="data" :rules="rules" :disabled="loading" ref="eventForm">
        <el-form-item
          :class="[
            'custom-item',
            'flex',
            'flex-col',
            { 'custom-item--disabled': isEventDisabled }
          ]"
          prop="type"
        >
          <UiSelect
            :label="$t('events')"
            :options="types"
            :placeholder="$t('reports.not-select')"
            :modelValue="currentType.label"
            :disabled="isEventDisabled"
            @update:modelValue="handleTypeUpdate"
          />
        </el-form-item>

        <el-form-item
          v-if="needShowField('datetime')"
          prop="datetime"
          :class="['custom-item', { 'custom-item--disabled': isEventDisabled }]"
        >
          <div class="picker-wrapper">
            <label class="label">{{ getLabel('datetime') }}:</label>
            <div class="flex items-top justify-around">
              <el-date-picker
                v-model="data.datetime"
                class="custom-picker"
                ref="dateTimePicker"
                type="datetime"
                prefix-icon="none"
                clear-icon="none"
                :format="dateTimeFormat"
                :disabled="isEventDisabled"
                :placeholder="$t('reports.not-select')"
              />
            </div>
            <CalendarIcon class="cursor-pointer picker-wrapper__icon" />
          </div>
        </el-form-item>
        <el-form-item
          v-if="needShowField('start_date')"
          prop="start_date"
          :class="['custom-item', { 'custom-item--disabled': isEventDisabled }]"
        >
          <div class="picker-wrapper">
            <label class="label">{{ getLabel('start_date') }}:</label>
            <div class="flex items-top justify-around">
              <el-date-picker
                class="custom-picker"
                v-model="data.start_date"
                ref="dateTimePicker"
                type="datetime"
                prefix-icon="none"
                clear-icon="none"
                :format="dateTimeFormat"
                :placeholder="$t('reports.not-select')"
                :disabled="isEventDisabled"
              />
            </div>
            <CalendarIcon class="cursor-pointer picker-wrapper__icon" />
          </div>
        </el-form-item>
        <el-form-item
          v-if="needShowField('finish_date')"
          prop="finish_date"
          :class="['custom-item', { 'custom-item--disabled': isEventDisabled }]"
        >
          <div class="picker-wrapper">
            <label class="label">{{ getLabel('finish_date') }}:</label>
            <div class="flex items-top justify-around">
              <el-date-picker
                v-model="data.finish_date"
                class="custom-picker"
                ref="dateTimePicker"
                type="datetime"
                prefix-icon="none"
                clear-icon="none"
                :format="dateTimeFormat"
                :placeholder="$t('reports.not-select')"
                :disabled="isEventDisabled"
              />
            </div>
            <CalendarIcon class="cursor-pointer picker-wrapper__icon" />
          </div>
        </el-form-item>

        <!-- <el-form-item prop="start_date" v-if="needShowField('start_date')">
          <UiDatePicker
            :format="dateTimeFormat"
            v-model="data.start_date"
            :label="getLabel('start_date')"
            :key="currentType + key"
            :placeholder="$t('reports.not-select')"
          />
        </el-form-item>

        <el-form-item prop="finish_date" v-if="needShowField('finish_date')">
          <UiDatePicker
            :format="dateTimeFormat"
            v-model="data.finish_date"
            :label="getLabel('finish_date')"
            :key="currentType + key"
            :placeholder="$t('reports.not-select')"
          />
        </el-form-item> -->

        <el-form-item
          v-if="needShowField('user_id')"
          class="flex flex-col"
          :class="[
            'custom-item',
            'flex',
            'flex-col',
            { 'custom-item--disabled': isEventDisabled }
          ]"
          prop="user_id"
        >
          <UiSelect
            :label="getLabel('user_id')"
            :options="computedDrivers"
            :placeholder="$t('reports.not-select')"
            :modelValue="data.user_id"
            :disabled="isEventDisabled"
            @update:modelValue="handleChangeDriver"
          />
        </el-form-item>

        <el-form-item
          v-if="needShowField('deviation_minutes')"
          prop="deviation_minutes"
          :class="[
            'custom-item',
            'flex',
            'flex-col',
            { 'custom-item--disabled': isEventDisabled }
          ]"
        >
          <UiInput
            type="number"
            :key="currentType.key + key"
            :label="getLabel('deviation_minutes')"
            :model-value="data.deviation_minutes"
            :placeholder="$t('reports.not-select')"
            :disabled="isEventDisabled"
            @update:modelValue="data.deviation_minutes = $event"
          />
        </el-form-item>

        <el-form-item
          v-if="needShowField('consumed')"
          prop="consumed"
          :class="['custom-item', { 'custom-item--disabled': isEventDisabled }]"
        >
          <UiInput
            type="number"
            :key="currentType.key + key"
            :label="getLabel('consumed')"
            :model-value="data.consumed"
            :placeholder="$t('reports.not-select')"
            :disabled="isEventDisabled"
            @update:modelValue="data.consumed = $event"
          />
        </el-form-item>

        <el-form-item
          v-if="needShowField('stolen')"
          prop="stolen"
          :class="[
            'custom-item',
            'flex',
            'flex-col',
            { 'custom-item--disabled': isEventDisabled }
          ]"
        >
          <UiInput
            type="number"
            :key="currentType.key + key"
            :label="getLabel('stolen')"
            :model-value="data.stolen"
            :placeholder="$t('reports.not-select')"
            :disabled="isEventDisabled"
            @update:modelValue="data.stolen = $event"
          />
        </el-form-item>

        <el-form-item
          v-if="needShowField('service_name')"
          :class="[
            'custom-item',
            'flex',
            'flex-col',
            { 'custom-item--disabled': isEventDisabled }
          ]"
          prop="service_name"
        >
          <UiSelect
            :label="getLabel('service_name')"
            :options="servicesUnical"
            :placeholder="$t('reports.not-select')"
            :model-value="data.service_name"
            :disabled="isEventDisabled"
            @update:modelValue="data.service_name = $event"
            :allow-create="true"
            filterable
          />
        </el-form-item>

        <el-form-item
          v-if="needShowField('motohours')"
          prop="motohours"
          :class="['custom-item', { 'custom-item--disabled': isEventDisabled }]"
        >
          <UiInput
            type="number"
            :key="currentType.key + key"
            :label="getLabel('motohours')"
            :model-value="data.motohours"
            :placeholder="$t('reports.not-select')"
            :disabled="isEventDisabled"
            @update:modelValue="data.motohours = $event"
          />
        </el-form-item>
        <el-form-item
          v-if="needShowField('millage')"
          prop="millage"
          :class="['custom-item', { 'custom-item--disabled': isEventDisabled }]"
        >
          <UiInput
            type="number"
            :key="currentType.key + key"
            :label="getLabel('millage')"
            :model-value="data.millage"
            :placeholder="$t('reports.not-select')"
            :disabled="isEventDisabled"
            @update:modelValue="data.millage = $event"
          />
        </el-form-item>
        <el-form-item
          v-if="needShowField('duration_hours')"
          prop="duration_hours"
          :class="['custom-item', { 'custom-item--disabled': isEventDisabled }]"
        >
          <UiInput
            type="number"
            :key="currentType.key + key"
            :label="getLabel('duration_hours')"
            :model-value="data.duration_hours"
            :placeholder="$t('reports.not-select')"
            :disabled="isEventDisabled"
            @update:modelValue="data.duration_hours = $event"
          />
        </el-form-item>
        <el-form-item
          v-if="needShowField('price')"
          prop="price"
          :class="['custom-item', { 'custom-item--disabled': isEventDisabled }]"
        >
          <UiInput
            type="number"
            :key="currentType.key + key"
            :label="getLabel('price')"
            :model-value="data.price"
            :placeholder="$t('reports.not-select')"
            :disabled="isEventDisabled"
            @update:modelValue="data.price = $event"
          />
        </el-form-item>
        <el-form-item
          v-if="needShowField('filled')"
          prop="filled"
          :class="['custom-item', { 'custom-item--disabled': isEventDisabled }]"
        >
          <UiInput
            type="number"
            :key="currentType.key + key"
            :label="getLabel('filled')"
            :model-value="data.filled"
            :placeholder="$t('reports.not-select')"
            :disabled="isEventDisabled"
            @update:modelValue="data.filled = $event"
          />
        </el-form-item>
        <el-form-item
          v-if="needShowField('guaranteed')"
          prop="guaranteed"
          :class="['custom-item', { 'custom-item--disabled': isEventDisabled }]"
        >
          <el-checkbox
            v-model="data.guaranteed"
            :disabled="isEventDisabled"
            :key="currentType.key + key"
          >
            {{ $t('events.guaranteed') }}
          </el-checkbox>
        </el-form-item>
        <el-form-item
          v-if="needShowField('type_name')"
          :class="[
            'custom-item',
            'flex',
            'flex-col',
            { 'custom-item--disabled': isEventDisabled }
          ]"
          prop="type_name"
        >
          <UiSelect
            class="type-select"
            filterable
            :key="currentType.key + key"
            :label="getLabel('type_name')"
            :options="eventVehicleStatusTypes"
            :placeholder="$t('reports.not-select')"
            :model-value="data.type_name"
            :disabled="isEventDisabled"
            @update:modelValue="data.type_name = $event"
            :allow-create="true"
          />
        </el-form-item>

        <el-form-item
          prop="duration_minutes"
          v-if="needShowField('duration_minutes')"
          :class="['custom-item', { 'custom-item--disabled': isEventDisabled }]"
        >
          <UiInput
            type="number"
            :key="currentType.key + key"
            :label="getLabel('duration_minutes')"
            :model-value="data.duration_minutes"
            :placeholder="$t('reports.not-select')"
            @update:modelValue="data.duration_minutes = $event"
            :disabled="isEventDisabled"
            :max="59"
          />
        </el-form-item>
        <el-form-item
          v-if="needShowField('random_duration_hours')"
          prop="random_duration_hours"
          :class="['custom-item', { 'custom-item--disabled': isEventDisabled }]"
        >
          <UiInput
            type="number"
            :key="currentType.key + key"
            :label="getLabel('type_duration_hours')"
            :model-value="data.duration_hours"
            :placeholder="$t('reports.not-select')"
            @update:modelValue="data.duration_hours = $event"
            :disabled="isEventDisabled"
          />
        </el-form-item>
        <el-form-item
          class="txt-area"
          prop="description"
          :class="['custom-item', { 'custom-item--disabled': isEventDisabled }]"
        >
          <label>{{ $t('events.description') }}</label>
          <el-input
            :key="currentType.key + key"
            v-model="data.description"
            type="textarea"
            maxlength="2000"
            rows="4"
            :disabled="isEventDisabled"
            class="txt-area__input"
          />
        </el-form-item>
        <!-- upload pictures -->
        <el-form-item
          prop="pictures"
          :class="[
            'flex',
            'flex-col',
            'upload',
            'custom-item',

            { 'upload--limit': uploadedCount >= limitFile }
          ]"
        >
          <label
            >{{ $t('events.imageview.images') }}({{ uploadedCount }}/{{
              limitFile
            }})</label
          >
          <el-upload
            ref="up"
            action="/api_v1/upload_photos/events"
            list-type="picture-card"
            :file-list="images"
            accept="image/*,image/jpeg"
            :auto-upload="true"
            :limit="limitFile"
            :on-success="handleUploadChange"
            :before-upload="beforeFotoUpload"
            :on-preview="openCustomDialog"
            :on-remove="removeFile"
            :before-remove="handleBeforeRemove"
            :disabled="isEventDisabled"
          >
            <i slot="default" class="el-icon-plus"></i>
          </el-upload>
        </el-form-item>
        <span v-if="isErrorUpload" class="warning font-SourceSansPro inline-block">{{
          $t('events.imageview.maxsize.msg')
        }}</span>
        <el-form-item
          :class="['custom-item', { 'custom-item--disabled': isEventDisabled }]"
        >
          <el-button
            class="submit-btn"
            type="primary"
            :disabled="loading"
            @click="submitForm"
          >
            {{ title === 1 ? $t('create') : $t('change') }}
          </el-button>
        </el-form-item>
      </el-form>
      <CustomDialog
        v-if="dialogVisible"
        :images="images"
        :currentIndex="currentIndex"
        @close="closeCustomDialog"
        @next="showNextImage"
        @previous="showPreviousImage"
      />
    </div>
  </div>
</template>

<script>
import websocketHandler from '@/mixins/websocketHandler.js'
import moment from 'moment'

import { trackingApi } from '@/api'
import { typesFields, typesRequiredFields } from '@/utils/events.js'
import { mapGetters, mapState } from 'vuex'
import { typesMap } from './utils/typesEvent'

import CalendarIcon from '@/components/unitsNew/assets/icons/calendar.vue'
import CloseIcon from './assets/icons/close-icon.vue'
import UiSelect from './components/Select.vue'
import UiInput from './components/Input.vue'
import CustomDialog from './components/Dialog.vue'

export default {
  name: 'EventScreen',
  components: {
    CloseIcon,
    UiSelect,
    UiInput,
    CalendarIcon,
    CustomDialog
  },
  mixins: [websocketHandler],
  props: {
    unit: {
      type: Object,
      default: () => ({})
    },
    title: {
      type: Number,
      default: Number(0)
    },
    eventToUpdate: {
      type: Object,
      default: () => ({})
    },
    isEventDisabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    const checkRequired = (rule, value, callback) => {
      if (!value && this.isRequiredField(rule.field)) {
        callback(new Error(this.$t('events.validation.required')))
      } else {
        callback()
      }
    }

    const checkNumber = (rule, value, callback) => {
      if (!value && this.isRequiredField(rule.field)) {
        callback(new Error(this.$t('events.validation.number')))
      } else {
        callback()
      }
    }

    return {
      currentColumns: [],
      limitFile: 10,
      dialogVisible: false,
      dialogImageUrl: '',
      images: [],
      currentIndex: 0,
      uploadedCount: 0,
      isDateTimePickerOpen: false,
      dateTimePicker: null,
      disabled: false,
      isRandom: false,
      eventsRandomNameTypes: [],
      eventVehicleStatusTypes: [],
      key: 0,
      timeOut: null,
      loading: false,
      currentType: {
        key: 'filling', // filling, theft, accident, service, waybill, random
        value: this.$t('notifications.fill'), // заправка
        type: 'event_type'
      },

      // date time format
      pickerOptions: {
        firstDayOfWeek: 1
      },
      dateFormat: '',
      timeFormat: '',
      dateTimeFormat: '',
      submitDateTimeFormat: 'yyyy-MM-DD HH:mm:ss',
      data: {
        duration_minutes: 0,
        duration_hours: 0,
        datetime: new Date(moment().format('YYYY-MM-DD HH:mm:ss')),
        deviation_minutes: '',
        filled: '',
        consumed: '',
        price: '',
        description: '',
        stolen: '',
        service_name: '',
        motohours: '',
        millage: '',
        random_duration_hours: 0,
        user_id: '',
        type_name: '',
        start_date: '',
        finish_date: '',
        guaranteed: false,
        // coords
        latitude: '',
        longitude: '',
        photos: []
      },
      rules: {
        datetime: [
          {
            type: 'date',
            required: true,
            message: this.$t('events.validation.datetime'),
            trigger: 'change'
          }
        ],
        start_date: [
          {
            type: 'date',
            required: true,
            message: this.$t('events.validation.datetime'),
            trigger: 'change'
          }
        ],
        finish_date: [
          {
            type: 'date',
            required: true,
            message: this.$t('events.validation.datetime'),
            trigger: 'change'
          }
        ],
        deviation_minutes: [{ validator: checkNumber, trigger: 'change' }],
        filled: [{ validator: checkNumber, trigger: 'change' }],
        consumed: [{ validator: checkNumber, trigger: 'change' }],
        stolen: [{ validator: checkNumber, trigger: 'change' }],
        service_name: [{ validator: checkRequired, trigger: 'change' }],
        type_name: [{ validator: checkRequired, trigger: 'change' }],
        user_id: [
          {
            required: true,
            message: this.$t('events.validation.driver'),
            trigger: 'blur'
          }
        ]
      },
      isErrorUpload: false
    }
  },
  computed: {
    ...mapState('dictionary', {
      types: state => state.event_type
    }),
    ...mapGetters('users', ['drivers']),
    ...mapState('login', ['me']),
    ...mapState('events', {
      randomTypes: state => state.events,
      lastPoints: state => state.lastPoints,
      nameTypes: state => state.nameTypes
    }),

    isHighRole() {
      return !!(
        this.me.role.key === 'CONTROLLER' ||
        this.me.role.key === 'TARIFF_EDITOR' ||
        this.me.role.key === 'SUPERVISOR' ||
        this.me.role.key === 'ADMIN'
      )
    },

    services() {
      return this.unit.services || []
    },
    currentFields() {
      const type = this.currentType.key

      return type && typesFields[type] ? typesFields[type] : []
    },
    requiredFields() {
      const type = this.currentType.key
      return type && typesRequiredFields[type] ? typesRequiredFields[type] : []
    },
    formEl() {
      return this.$refs.eventForm
    },
    randomTypesUnical() {
      const res = []
      const items = this.randomTypes || []
      items.forEach(item => {
        const val = item.type_name
        if (val && !res.includes(val)) {
          res.push(val)
        }
      })
      return res
    },
    servicesUnical() {
      let defaultsItems = [
        'Осмотр',
        'Замена терминала',
        'Установка ДУТ',
        'Замена ДУТ',
        'Калибровка ДУТ'
      ]
      const items = this.services || []
      const lang = this.$i18n.locale()
      if (lang === 'en') {
        defaultsItems = [
          'Inspection',
          'Terminal replacement',
          'FLS installation',
          'FLS replacement',
          'FLS calibration'
        ]
      } else if (lang === 'kz') {
        defaultsItems = [
          'Тексеру',
          'Терминалды ауыстыру',
          'FLS орнату',
          'FLS ауыстыру',
          'FLS калибрлеу'
        ]
      }
      const res = defaultsItems
      items.forEach(item => {
        const val = item.name
        if (val && !res.includes(val)) {
          res.push(val)
        }
      })
      return res
    },
    subtitle() {
      return `${this.$t('object')} : ${this.unit ? this.unit.name : ''}`
    },
    computedCurrentType: {
      get() {
        return this.currentType
      },
      set(type) {
        this.currentType = type
      }
    },
    computedDrivers() {
      return this.drivers.map(driver => ({ name: driver.name, id: driver.id }))
    }
  },
  watch: {
    'currentType.key'(val) {
      this.resetForm()

      if (val === 'service' && this.currentColumns) {
        const currentMillage = this.currentColumns.find(item => item.type === 'mileage')
        if (currentMillage && currentMillage.value) {
          this.data.millage = currentMillage.value
        }
        const currentMotohours = this.currentColumns.find(
          item => item.type === 'abs_motohours'
        )
        if (currentMotohours && currentMotohours.value) {
          this.data.motohours = currentMotohours.value
        }
      } else {
        this.data.millage = 0
        this.data.motohours = 0
      }
      // this.setDefaults()

      if (val === 'random') {
        this.getTypeNames('random')
        this.isRandom = true
      }
      if (val === 'vehicle_status') {
        this.getTypeNames('vehicle_status')
        this.$nextTick(() => {
          const selectTypeName = document.querySelector('[name="selectTypeName"]')
          if (selectTypeName) {
            selectTypeName.maxLength = 20
          }
        })
      }
    },

    'data.datetime': function (val) {
      if (!val) return
      clearTimeout(this.timeOut)

      this.timeOut = setTimeout(() => {
        this.setCoords(val)
      }, 1500)
    }
  },
  methods: {
    beforeFotoUpload(file) {
      this.isErrorUpload = false
      const isFoto = file.type === 'image/jpeg' || 'image/png'
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isFoto) {
        // this.$message.error('Avatar picture must be JPG format!')
      }
      if (!isLt2M) {
        this.isErrorUpload = true
        // this.$message.error('Avatar picture size can not exceed 2MB!')
      }
      return isFoto && isLt2M
    },
    openPicker() {
      if (this.isDateTimePickerOpen) {
        this.isDateTimePickerOpen = false
        this.dateTimePicker.hidePicker()
      } else {
        this.isDateTimePickerOpen = true
        this.dateTimePicker.showPicker()
      }
    },

    handleTypeUpdate(type) {
      this.currentType = type
    },
    handleChangeDriver(id) {
      this.data.user_id = id
    },
    setCoordData(coords) {
      if (coords && coords[0] && coords[1]) {
        this.data.latitude = coords[0]
        this.data.longitude = coords[1]
      }
    },
    async setCoords(date) {
      try {
        const from = moment(new Date(date)).format(this.submitDateTimeFormat)

        const formData = {
          units: [{ id: this.unit.id }],
          from
        }
        const res = await trackingApi.postPoint({ formData })
        this.currentColumns = res.data.columns
        const coords = res.data.columns.find(item => item.key === '_marker_latlon').value

        this.setCoordData(coords)
      } catch (error) {
        this.setCoordsFromWS()
      }
    },
    setCoordsFromWS() {
      const unitId = this.unit.id
      const coords =
        this.lastPoints && this.lastPoints[unitId] ? this.lastPoints[unitId] : null
      this.setCoordData(coords)
    },
    getLabel(field) {
      return this.$t(`events.${field}`)
    },
    needShowField(name) {
      return name && this.currentFields.includes(name)
    },
    isRequiredField(name) {
      return name && this.requiredFields.includes(name)
    },
    submitForm() {
      this.formEl.validate(valid => {
        if (valid) {
          this.createOrUpdate()
        } else {
          return false
        }
      })
    },
    createSubmitData() {
      const cleanEmpty = {}
      Object.keys(this.data).forEach(key => {
        if (
          this.data[key] ||
          (key === 'guaranteed' && this.currentType.key === 'service')
        ) {
          cleanEmpty[key] = this.data[key]
        }
      })

      const data = {
        unit: { id: this.unit.id },
        type: { key: this.currentType.key },
        ...cleanEmpty
      }

      const dateField = ['datetime', 'start_date', 'finish_date']
      dateField.forEach(key => {
        if (data[key]) {
          data[key] = moment(new Date(data[key])).format(this.submitDateTimeFormat)
        }
      })
      return data
    },
    async createOrUpdate() {
      const data = this.createSubmitData()

      this.loading = true
      try {
        if (this.title === 1) {
          await this.$store.dispatch('events/CREATE', data)
        } else {
          await this.$store.dispatch('events/UPDATE', {
            ...data,
            id: this.eventToUpdate.id
          })
        }
        this.$notify.success({
          message: this.$t('notification_alert.success'),
          position: 'top-right'
        })
        this.$emit('close')
      } catch (error) {
        console.error('error:', error)
      }
      this.loading = false
    },
    resetForm() {
      this.formEl.resetFields()
    },
    clearValidate() {
      this.formEl.clearValidate()
    },
    setDefaults() {
      const data = {}

      Object.keys(this.data).forEach(key => {
        if (key === 'guaranteed') {
          data[key] = false
        } else {
          data[key] = ''
        }
      })

      if (this.currentType.key === 'waybill') {
        data.start_date = new Date()
        // data.datetime = ''
      } else {
        data.start_date = new Date()
        data.datetime = new Date()
      }

      if (this.currentType.key === 'service') {
        data.guaranteed = true
        data.service_name =
          this.servicesUnical && this.servicesUnical.length ? this.servicesUnical[0] : ''
      }
      this.data = Object.assign(this.data, data)
    },
    async getTypeNames(value) {
      const formData = {
        from: 0,
        count: 100,
        fields: ['type_name'],
        conditions: [
          {
            field: 'type.key',
            value
          }
        ]
      }
      try {
        await this.$store.dispatch('events/GET_TYPENAMES', formData)
        await this.$nextTick()
        this.nameTypes.forEach(name => {
          if (!this.eventVehicleStatusTypes.includes(name.type_name)) {
            this.eventVehicleStatusTypes.push(name.type_name)
          }
        })
        console.log('Updated eventVehicleStatusTypes:', this.eventVehicleStatusTypes)
      } catch (error) {
        console.error('Error fetching type names:', error)
      }
    },

    handleUploadChange(response, file, fileList) {
      const baseUrl = 'api_v1'

      this.images = fileList.map(file => ({
        ...file,
        url: `${baseUrl}${file.response?.small?.url || file.small.url}`,
        originalUrl: `${baseUrl}${file.response?.original?.url || file.original.url}`
      }))

      this.data.photos.push(response)
      this.uploadedCount = this.data.photos.length
    },
    openCustomDialog(file) {
      // find the index of the image in the images array
      this.currentIndex = this.images.findIndex(image => image.uid === file.uid)
      this.dialogVisible = true
    },
    closeCustomDialog() {
      this.dialogVisible = false
    },
    removeFile(file, fileList) {
      const index = this.images.indexOf(file)
      if (index !== -1) {
        this.images.splice(index, 1)
        this.data.photos.splice(index, 1)
      }
      this.uploadedCount = fileList.length
    },
    handleBeforeRemove(response, file, fileList) {
      if (this.isEventDisabled) {
        return false
      }
    },
    showNextImage() {
      if (this.currentIndex < this.images.length - 1) {
        this.currentIndex++
      }
    },
    showPreviousImage() {
      if (this.currentIndex > 0) {
        this.currentIndex--
      }
    },
    setupImages() {
      // Check if eventToUpdate.photos exists and has at least one photo
      if (!this.eventToUpdate.photos || this.eventToUpdate.photos.length === 0) {
        return
      }
      // Transform each photo URL and create images array
      const baseUrl = 'api_v1'

      this.images = this.eventToUpdate.photos.map(photo => ({
        ...photo,

        url: `${baseUrl}${photo.small.url}`, // Combine base URL with original photo URL
        originalUrl: `${baseUrl}${photo.original.url}`
      }))

      // Update uploadedCount with the length of the images array
      this.uploadedCount = this.images.length
    },
    initializeEvent(event) {
      this.setType(event)
      this.updateData(event)
      this.setupImages()
      this.setDriver()
    },
    setType(event) {
      if (this.types.some(option => option?.key === event?.type?.key)) {
        this.currentType.key = event?.type?.key
        this.computedCurrentType.key = event?.type?.key
        this.computedCurrentType.label = typesMap[event?.type?.key]
      }
    },
    updateData(event) {
      this.$nextTick(() => {
        for (const key in event) {
          if (Object.prototype.hasOwnProperty.call(event, key)) {
            this.data[key] = event[key]
          }
        }
        if (this.data.datetime) {
          this.data.datetime = new Date(event.datetime)
        }
        if (this.data.start_date) {
          this.data.start_date = new Date(event.start_date)
        }
        if (this.data.finish_date) {
          this.data.finish_date = new Date(event.finish_date)
        }

        this.data.description = event.description
        this.data.deviation_minutes = event.deviation_minutes
      })
    },
    setDriver() {
      this.$nextTick(() => {
        const driver = this.drivers.find(el => el.id === this.data.user_id)
        if (driver) {
          this.data.user_id = driver.id
        }
      })
    }
  },

  async created() {
    await this.$store.dispatch('dictionary/getDictionaryByType', 'event_type')
    this.dateFormat = this.me.active_company.dateformat.key
    this.timeFormat = this.me.active_company.timeformat.key
    this.dateTimeFormat = `${this.dateFormat} ${this.timeFormat}`
  },
  mounted() {
    if (this.eventToUpdate) {
      this.initializeEvent(this.eventToUpdate)
    }
    // coordinates should be set to /points by default
    this.setCoords(this.data.datetime)
  }
}
</script>
<style lang="stylus">
.custom-item {
  &--disabled {
    .el-form-item__content {
      opacity: 0.5;
      pointer-events: none;
    }
  }
}

[class*=" el-icon-"]
  vertical-align middle
  height auto
.skif-event-form {
  .el-date-editor {
    width: 13rem;
  }
}

.skif-form {
  &__row {
    margin: 20px 0;

    &.--flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__label {
    display: block;
    margin: 0 0 15px 0;
    font-family: Source Sans Pro;
    font-weight: 600;
    font-size: 13px;
    line-height: 19px;
    color: #5477A9;
  }

  &__icon {
    width: 1em;
    height: 1em;
    vertical-align: baseline;
  }

  &__info-text {
    margin: 30px 0 0 0;
    color: #5477A9;
    font-size: 14px;
    line-height: 1;
  }

  &__star {
    color: #EB5757;
    display: inline-block;
    margin-right: 0.3em;
  }
}
</style>
<style lang="scss" scoped>
.header {
  border-bottom: 1px solid #e5e5e5;
}
.txt-area {
  background-color: #edeff3;
  border-radius: 12px;
  padding: 0px 12px;
  & label {
    font-size: 13px;
    line-height: 0px;
    font-weight: 700;
    color: #292929;
    margin-top: 8px;
  }
  & .txt-area__input {
    :deep(textarea) {
      background-color: #edeff3;
      border: none;
      margin: 0;
      padding: 0;
    }
    border: none;
    background-color: #edeff3;
    padding: 0;
    margin: 0;
  }
}
.upload {
  border: none;
  background-color: #edeff3;
  padding: 0 12px 12px 12px;
  border-radius: 12px;

  margin: 0;
  & label {
    font-size: 13px;
    line-height: 0px;
    font-weight: 700;
    color: #292929;
    margin-top: 8px;
  }
  :deep(.el-upload--picture-card) {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 109px;
    height: 109px;
  }
  :deep(.el-upload-list__item) {
    width: 109px;
    height: 109px;
  }
  &--limit {
    &:deep(.el-upload--picture-card) {
      display: none;
    }
  }
}
.submit-btn {
  width: 100%;
  margin-top: 10px;
  background: #2767b6;
  border-radius: 8px;
  border: none;
}

.picker-wrapper {
  display: flex;
  flex-direction: column;
  background-color: #edeff3;
  border-radius: 12px;
  padding: 6px 12px;
  width: 100%;
  height: 50px;

  &:deep(.custom-picker) {
    width: 100%;
    .el-input__inner {
      background-color: #edeff3;
      border: none;
      padding-left: 0;
      height: 20px;
      color: #292929;
      font-weight: normal;
    }
    .el-input__suffix {
      height: 20px;
    }
    .el-select__caret {
      font-size: 16px;
      color: #292929;
    }
  }
  &__icon {
    position: absolute;
    top: 8px;
    right: 8px;
  }
}
.label {
  font-size: 13px;
  line-height: 0px;
  font-weight: 700;
  color: #292929;
  display: block;
  margin-top: 8px;
}
.warning {
  color: #c93131;
  font-size: 12px;
  line-height: 14px;
}
.type-select {
  :deep(.el-select-dropdown__item) {
    width: 381px;
  }
}
</style>
